/*
 * @Author: your name
 * @Date: 2022-04-26 09:49:38
 * @LastEditTime: 2025-03-17 15:32:18
 * @LastEditors: 番茄炒蛋蛋 wzh15802755802@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \jzjy\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../layout/HomeView.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    children: [
      // 主页
      {
        path: "/",
        name: "Home",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/DashBoard/index.vue"
          ),
      },
      //定制资源包页面
      {
        path: "/dingzhi",
        name: "Dingzhi",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/DashBoard/dingzhi.vue"
          ),
      },
      //教研服务包页面
      {
        path: "/jiaoyanbao",
        name: "Jiaoyanbao",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/DashBoard/jiaoyanbao.vue"
          ),
      },
      //平台协议页
      {
        path: "/sysinfo",
        name: "Sysinfo",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Sysinfo/index.vue"
          ),
      },
      //关于我们页
      {
        path: "/sysinfo/gywm",
        name: "Gywm",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Sysinfo/gywm.vue"
          ),
      },
      //搜索页
      {
        path: "/searchRes",
        name: "SearchRes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/SearchRes/index.vue"
          ),
      },
      // 活动页
      {
        path: "/active",
        name: "Active",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ActiveCenter/index.vue"
          ),
      },
      // 活动成果页
      {
        path: "/active/activeDetails",
        name: "ActiveDetails",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ActiveCenter/activeDetails.vue"
          ),
      },
      // 查看更多活动列表页
      {
        path: "/active/seeMoreActive",
        name: "SeeMoreActive",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ActiveCenter/seeMoreActive.vue"
          ),
      },
      //直播全屏页
      {
        path: "/active/fullScreen",
        name: "FullScreen",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ActiveCenter/fullScreen.vue"
          ),
      },
      //直播管理页
      {
        path: "/active/liveManage",
        name: "LiveManage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ActiveCenter/liveManage.vue"
          ),
      },
      // 名师工作室主页
      {
        path: "/tecStudio",
        name: "TecStudio",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/teacherStudio/index.vue"
          ),
      },
      // 名师工作室详情页
      {
        path: "/studioDetails",
        name: "StudioDetails",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/teacherStudio/studioDetails.vue"
          ),
      },
      // 名师入住
      {
        path: "/msrz",
        name: "Msrz",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/teacherStudio/msrz.vue"
          ),
      },
      // 资源中心页
      {
        path: "/resource",
        name: "Resource",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/index.vue"
          ),
        meta: {
          keepAlive: true, //此组件不需要被缓存
        }
      },
      // 精选资源主页
      {
        path: "/resourceJx",
        name: "ResourceJx",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceJx/index.vue"
          ),
        meta: {
          keepAlive: true, //此组件不需要被缓存
        }
      },
      // 同步资源主页
      {
        path: "/resourceTb",
        name: "ResourceTb",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceTb/index.vue"
          ),
        meta: {
          keepAlive: true, //此组件不需要被缓存
        }
      },
      // 资源中心页/教研课详情
      {
        path: "/resource/jiaoyanClass",
        name: "JiaoyanClass",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/jiaoyanClass.vue"
          ),
      },
      // 资源中心页/备课资源包
      {
        path: "/resource/beikeziyuan",
        name: "Beikeziyuan",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/beikeziyuan.vue"
          ),
      },
      // 资源中心页/备课资源包
      {
        path: "/resource/beikeziyuanCeshi",
        name: "BeikeziyuanCeshi",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/beikeziyuanCeshi.vue"
          ),
      },
      // 备课资源包免登录查看
      {
        path: "/resource/beikeYk",
        name: "BeikeYk",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/beikeYk.vue"
          ),
      },
      // 资源中心页/资源详情页
      {
        path: "/resource/resDetails",
        name: "ResDetails",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/resDetails.vue"
          ),
      },
      // 资源详情页免登录查看
      {
        path: "/resource/resDetYk",
        name: "ResDetYk",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/resDetYk.vue"
          ),
      },
      // 资源详情页免登录查看教研包跳转过来下载
      {
        path: "/resource/resDetJyb",
        name: "ResDetJyb",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/resDetJyb.vue"
          ),
      },
      // 格式为zip和rar的资源详情页
      {
        path: "/resource/zipResDetails",
        name: "ZipResDetails",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/zipResDetails.vue"
          ),
      },
      // 资源详情页免登录查看教研包跳转过来下载
      {
        path: "/resource/zipResDetJyb",
        name: "ZipResDetJyb",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/zipResDetJyb.vue"
          ),
      },
      // 资源中心页/高三最新资源页
      {
        path: "/resource/gszy",
        name: "Gszy",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/gszy.vue"
          ),
      },
      // 资源中心页/高三最新资源页
      {
        path: "/resource/newzy",
        name: "Newzy",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/newzy.vue"
          ),
      },
      // 资源中心页/高三最新资源页
      {
        path: "/resourceJx/gszx",
        name: "Gszx",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/gszx.vue"
          ),
      },
      // 资源中心页/收藏分享列表页
      {
        path: "/resource/resAllfx",
        name: "ResAllfx",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ResourceCenter/resAllfx.vue"
          ),
      },
      // 产品中心页
      {
        path: "/product",
        name: "Product",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ProductCenter/index.vue"
          ),
      },
      // 产品中心页/产品详情页
      {
        path: "/product/productDetails",
        name: "ProductDetails",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ProductCenter/productDetails.vue"
          ),
      },
      // 产品中心页/全屏阅读页
      {
        path: "/product/fullScreenBooks",
        name: "FullScreenBooks",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ProductCenter/fullScreenBooks.vue"
          ),
      },
      // 产品中心页/产品详情页
      {
        path: "/product/classAll",
        name: "ClassAll",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ProductCenter/classAll.vue"
          ),
      },
      //在线课程页
      {
        path: "/product/classOnline",
        name: "ClassOnline",
        component: () => import(/*webpackChunkName:"about"*/"../views/ProductCenter/classOnline.vue")
      },
      //精听训练营详情页
      {
        path: "/product/jingtingDetails",
        name: "JingtingDetails",
        component: () => import(/*webpackChunkName:"about"*/"../views/ProductCenter/jingtingDetails.vue")
      },
      // 个人中心页
      {
        path: "/personal",
        name: "Personal",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/PersonalCenter/index.vue"
          ),
        children: [
          // 个人中心页/个人信息
          {
            path: "/personal",
            name: "MyIndex",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/MyIndex/index.vue"
              ),
            children: [
              // 个人中心页/我的主页/个人信息
              {
                path: "/personal/personalMsg",
                name: "PersonalMsg",
                component: () =>
                  import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/PersonalMsg/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal'
                  }
              },
            ]
          },
          // 个人中心页/切换学科
          {
            path: "/personal/ChangeClass",
            name: "ChangeClass",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/ChangeClass/index.vue"
              ),
          },
          // 个人中心页/我的下载
          {
            path: "/personal/MyDownLoad",
            name: "MyDownLoad",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/MyDownLoad/index.vue"
              ),
          },
          // 个人中心页/活动列表
          {
            path: "/personal/MyActive",
            name: "MyActive",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/MyActive/index.vue"
              ),
          },
          // 个人中心页/收藏列表
          {
            path: "/personal/MyCollect",
            name: "MyCollect",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/MyCollect/index.vue"
              ),
          },
          // 个人中心页/我的订单
          {
            path: "/personal/MyClass",
            name: "MyClass",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/MyClass/index.vue"
              ),
          },
          // 个人中心页/教学计划
          {
            path: "/personal/MyTeachPlan",
            name: "MyTeachPlan",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/MyTeachPlan/index.vue"
              ),
          },
          // 个人中心页/我的订单
          {
            path: "/personal/OrderList",
            name: "OrderList",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/OrderList/index.vue"
              ),
          },
          // 个人中心页/我的订单详情页
          {
            path: "/personal/OrderList/details",
            name: "orderDetails",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/OrderList/details.vue"
              ),
              meta:{
                activeIndex:'/personal/OrderList'
              }
          },
          // 个人中心页/问卷调查
          {
            path: "/personal/Question",
            name: "Question",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/Question/index.vue"
              ),
          },
          // 个人中心页/客服
          {
            path: "/personal/Custom",
            name: "Custom",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/Custom/index.vue"
              ),
          },
          // 个人中心页/个人数据
          {
            path: "/personal/MyData",
            name: "MyData",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/MyData/index.vue"
              ),
            children: [
              {
                path: "/personal/MyData",
                name: "AllData",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/PersonalCenter/MyData/AllData/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal/MyData'
                  }
              },
              {
                path: "/personal/MyData/ActiveData",
                name: "ActiveData",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/PersonalCenter/MyData/ActiveData/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal/MyData'
                  }
              },
              {
                path: "/personal/MyData/ResourceData",
                name: "ResourceData",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/PersonalCenter/MyData/ResourceData/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal/MyData'
                  }
              },
              {
                path: "/personal/MyData/ProductData",
                name: "ProductData",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/PersonalCenter/MyData/ProductData/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal/MyData'
                  }
              },
            ],
          },
          // 个人中心页/学校数据
          {
            path: "/personal/SchoolData",
            name: "SchoolData",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/SchoolData/index.vue"
              ),
            children: [
              {
                path: "/personal/SchoolData",
                name: "AllData",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/PersonalCenter/SchoolData/AllData/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal/SchoolData'
                  }
              },
              {
                path: "/personal/SchoolData/ActiveData",
                name: "ActiveData",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/PersonalCenter/SchoolData/ActiveData/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal/SchoolData'
                  }
              },
              {
                path: "/personal/SchoolData/ResourceData",
                name: "ResourceData",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/PersonalCenter/SchoolData/ResourceData/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal/SchoolData'
                  }
              },
              {
                path: "/personal/SchoolData/ProductData",
                name: "ProductData",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../views/PersonalCenter/SchoolData/ProductData/index.vue"
                  ),
                  meta:{
                    activeIndex:'/personal/SchoolData'
                  }
              },
            ],
          },
          // 个人中心页/资源订阅
          {
            path: "/personal/Subscription",
            name: "Subscription",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/PersonalCenter/Subscription/index.vue"
              ),
          },
        ],
      },
      // 帮助中心
      {
        path: "/helpCenter",
        name: "HelpCenter",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/HellpCenter/helpCenter.vue"
          ),
      },
      // 视频教程
      {
        path: "/videoTeach",
        name: "VideoTeach",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/HellpCenter/videoTeach.vue"
          ),
      },
      // 意见反馈
      {
        path: "/feedBack",
        name: "FeedBack",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/HellpCenter/feedBack.vue"
          ),
      },
      //在线客服
      {
        path: "/customOnline",
        name: "CustomOnline",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/HellpCenter/customOnline.vue"
          ),
      },
      //资源订阅详情页
      {
        path: "/personal/subscriptionDetails",
        name: "SubscriptionDetails",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/PersonalCenter/subscriptionDetails"
          ),
      },
      // AI教研
      {
        path: "/AIjiaoyan",
        name: "AIjiaoyan",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/AIjiaoyan/index.vue"
          ),
        children: [
          // 个人中心页/个人信息
          {
            path: "/AIjiaoyan",
            name: "conversation",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/AIjiaoyan/conversation.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login/login.vue"),
  },
  {
    path: "/login/update",
    name: "Update",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login/update.vue"),
  },
  {
    path: "/reset",
    name: "Reset",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Reset/resetPass.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.afterEach((to,from,next)=>{

//   window.scrollTo(0,0);
//   document.body.scrollTop = 0; 
//   document.documentElement.scrollTop = 0;
//   window.pageYOffset = 0;
//   next();

// })

export default router;
