<!--
 * @Author: your name
 * @Date: 2022-05-05 09:16:14
 * @LastEditTime: 2025-02-20 14:55:09
 * @LastEditors: 番茄炒蛋蛋 wzh15802755802@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \jzjy\src\components\floating\floating.vue
-->
<!--
 右侧浮窗，客服、电话、回到顶部
-->
<template>
  <div class="container">
    <ul id="ulBox">
      <el-tooltip
        popper-class="banbenchose"
        placement="left"
        effect="light"
        :visible-arrow="false"
        v-if="is_nb == 1"
        v-show="kemu_id == 1 || kemu_id == 3"
      >
        <div slot="content" class="banbenchose">
          <div class="banben" v-for="item in banbenList" :key="item.id" @click="checkBb(item.id)">
            <img
              src="../../assets/img/images/floting_nochose.png"
              v-if="kejianversions_id != item.id"
            />
            <img src="../../assets/img/images/floting_chosed.png"  v-if="kejianversions_id == item.id" />
            <p class="bbtitle" :class="{choseBb:kejianversions_id == item.id}">{{ item.kemu_id == 3 ? "英语" : "语文" }} / {{ item.title }}</p>
          </div>
        </div>
        <li class="check" @mouseenter="showit5" @mouseleave="ovit5" @click="changKm">
          <img
            src="../../assets/img/images/kemu (8).png"
            style="wdith: 20px"
            v-show="picFlag && activeIndex == 5"
            v-if="kemu_id == 1"
          />
          <img
            src="../../assets/img/images/kemu (7).png"
            style="wdith: 20px"
            v-show="!picFlag || activeIndex != 5"
            v-if="kemu_id == 1"
          />
          <!-- <img
            src="../../assets/img/images/kemu (4).png"
            style="wdith: 20px"
            v-show="picFlag && activeIndex == 5"
            v-if="kemu_id == 3"
          />
          <img
            src="../../assets/img/images/kemu (3).png"
            style="wdith: 20px"
            v-show="!picFlag || activeIndex != 5"
            v-if="kemu_id == 3"
          /> -->
          <img
            src="../../assets/img/images/floting_en2.png"
            style="wdith: 20px"
            v-show="picFlag && activeIndex == 5"
            v-if="kemu_id == 3"
          />
          <img
            src="../../assets/img/images/floting_en1.png"
            style="wdith: 20px"
            v-show="!picFlag || activeIndex != 5"
            v-if="kemu_id == 3"
          />
          <span>切换</span>
          <span>学科</span>
        </li>
      </el-tooltip>
      <el-tooltip placement="left" effect="light" :visible-arrow="false">
        <div
          slot="content"
          style="
            fontsize: 16px;
            textalign: center;
            height: 40px;
            lineheight: 40px;
            color: #1E60EB;
          "
        >
          {{ floaterMsg.telphone }}
        </div>
        <li class="phone" @mouseenter="showit1" @mouseleave="ovit1">
          <img
            src="../../assets/img/images/floting_phone1.png"
            v-show="picFlag && activeIndex == 1"
          />
          <img
            src="../../assets/img/images/floting_phone2.png"
            v-show="!picFlag || activeIndex != 1"
          />
          <span>客服</span>
          <span>电话</span>
        </li>
      </el-tooltip>
      <el-tooltip placement="left" effect="light" :visible-arrow="false">
        <div slot="content">
          <img
            :src="floaterMsg.kf_file_url"
            alt=""
            class="evm"
            style="width: 120px"
          />
        </div>
        <li class="wechat" @mouseenter="showit2" @mouseleave="ovit2">
          <img
            src="../../assets/img/images/floting_wchat1.png"
            v-show="picFlag && activeIndex == 2"
          />
          <img
            src="../../assets/img/images/floting_wchat2.png"
            v-show="!picFlag || activeIndex != 2"
          />
          <span>客服</span>
          <span>微信</span>
        </li>
      </el-tooltip>

      <el-tooltip placement="left-start" effect="light" :visible-arrow="false">
        <div slot="content">
          <img
            :src="floaterMsg.xcx_file_url"
            alt=""
            class="evm"
            style="width: 120px"
          />
        </div>
        <li class="xcx" @mouseenter="showit3" @mouseleave="ovit3" style="height:40px">
          <img
            src="../../assets/img/images/floting_xcx1.png"
            v-show="picFlag && activeIndex == 3"
          />
          <img
            src="../../assets/img/images/floting_xcx2.png"
            v-show="!picFlag || activeIndex != 3"
          />
          <span>小程序</span>
        </li>
      </el-tooltip>
      <li
        class="fankui"
        @click="goFk"
        @mouseenter="showit4"
        @mouseleave="ovit4"
      >
        <img
          src="../../assets/img/images/floting_mes1.png"
          v-show="picFlag && activeIndex == 4"
        />
        <img
          src="../../assets/img/images/floting_mes2.png"
          v-show="!picFlag || activeIndex != 4"
        />
        <span>意见</span>
        <span>反馈</span>
      </li>
    </ul>
    <div class="goTop" @click="backTop(step)" v-show="flag">
      <i class="el-icon-arrow-up"></i>
      <span>回顶部</span>
    </div>
  </div>
</template>

<script>
import { kemulists, versionslists, nowkemu, setnowkemu ,islogin ,userinfo} from "../../api/http";
export default {
  inject: ["reload"], //刷新页面
  // props: {
  //   step: {
  //     //控制速度
  //     type: Number,
  //     default: 80,
  //   },
  // },
  props: ["floaterMsg", "chechShow"],
  data() {
    return {
      flag: true,
      picFlag: true,
      activeIndex: 0,
      lists: [
        {
          di: 1,
          url1: require("../../assets/img/images/floting_phone1.png"),
          url2: require("../../assets/img/images/floting_phone2.png"),
          name1: "客服",
          name2: "电话",
        },
        {
          di: 2,
          url1: require("../../assets/img/images/floting_wchat1.png"),
          url2: require("../../assets/img/images/floting_wchat2.png"),
          name1: "客服",
          name2: "微信",
        },
        {
          di: 3,
          url1: require("../../assets/img/images/floting_xcx1.png"),
          url2: require("../../assets/img/images/floting_xcx2.png"),
          name1: "",
          name1: "小程序",
        },
        {
          di: 4,
          url1: require("../../assets/img/images/floting_mes1.png"),
          url2: require("../../assets/img/images/floting_mes2.png"),
          name1: "意见",
          name2: "反馈",
        },
      ],
      telphone: "", //客服电话
      kf_file_url: "", //客服二维码
      xcx_file_url: "", //小程序二维码
      kemu_id: "", //科目id
      kejianversions_id: "", //版本id
      banbenList: [], //版本列表
      is_nb:'',//是不是内部用户
    };
  },
  created() {
    
    //如果有登录信息
    islogin({ md5key: localStorage.getItem("md5key") }).then((res) => {
      if (res.retRes.login_status == 1) {
        this.getkmbb();
        //如果是内部用户
        console.log(123)
        userinfo({ md5key: localStorage.getItem("md5key") }).then(res=>{
          //展示侧边栏切换学科快捷按钮
          if(res.retRes.is_nb == 1){
            this.is_nb = 1
            this.getkmbb();
          }else{
            this.is_nb = ''
          }
        })
      }
    });
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },
  methods: {
    //获取科目版本
    getkmbb(){
      nowkemu({
          md5key: localStorage.getItem("md5key"),
        }).then((res) => {
          console.log(res, "当前科目及版本123");
          const data = res.retRes;
          if (res.retInt == 1) {
            this.kemu_id = data.kemu_id;
            this.kejianversions_id = data.kejianversions_id;
            //获取教学版本列表
            versionslists({
              kemu_id: this.kemu_id,
            }).then((res) => {
              this.banbenList = res.retRes;
            });
          }
        });
    },
    //设置版本和科目
    setkmbb(){
      versionslists({
        kemu_id: this.kemu_id,
      }).then((res) => {
       
        this.kejianversions_id = res.retRes[0].id
        setnowkemu({
          md5key: localStorage.getItem("md5key"),
          kemu_id: this.kemu_id,
          kejianversions_id: this.kejianversions_id,
          is_now: 1
        }).then((res) =>{
          if(res.retInt == 1){
            setTimeout(()=>{
              this.reload()
            },100)
          }
        })
         this.banbenList = res.retRes;
      });
    },
    //切换科目
    changKm(){
      if(this.kemu_id == 1){
        this.kemu_id = 3
        this.setkmbb();
      }else{
         this.kemu_id = 1
        this.setkmbb();
      }
    },
    //切换版本
    checkBb(id){
      this.kejianversions_id = id;
      setnowkemu({
        md5key: localStorage.getItem("md5key"),
        kemu_id: this.kemu_id,
        kejianversions_id: this.kejianversions_id,
        is_now: 1
      }).then((res) =>{
        if(res.retInt == 1){
          setTimeout(()=>{
            this.reload()
          },0)
        }
      })
    },
    //鼠标移入变色
    showit1() {
      let li1 = document.getElementsByClassName("phone")[0];
      li1.style.background = "#1E60EB";
      li1.style.color = "#FFF";
      this.picFlag = true;
      this.activeIndex = 1;
    },
    //鼠标移出
    ovit1() {
      let li1 = document.getElementsByClassName("phone")[0];
      li1.style.background = "#FFF ";
      li1.style.color = "#939599";
      this.picFlag = false;
      this.activeIndex = 1;
    },
    //鼠标移入变色
    showit2() {
      let li2 = document.getElementsByClassName("wechat")[0];
      li2.style.background = "#1E60EB";
      li2.style.color = "#FFF";
      this.picFlag = true;
      this.activeIndex = 2;
    },
    //鼠标移出
    ovit2() {
      let li2 = document.getElementsByClassName("wechat")[0];
      li2.style.background = "#FFF ";
      li2.style.color = "#939599";
      this.picFlag = false;
      this.activeIndex = 2;
    },
    //鼠标移入变色
    showit3() {
      let li3 = document.getElementsByClassName("xcx")[0];
      li3.style.background = "#1E60EB";
      li3.style.color = "#FFF";
      this.picFlag = true;
      this.activeIndex = 3;
    },
    //鼠标移出
    ovit3() {
      let li3 = document.getElementsByClassName("xcx")[0];
      li3.style.background = "#FFF ";
      li3.style.color = "#939599";
      this.picFlag = false;
      this.activeIndex = 3;
    },
    //鼠标移入变色
    showit4() {
      let li4 = document.getElementsByClassName("fankui")[0];
      li4.style.background = "#1E60EB";
      li4.style.color = "#FFF";
      this.picFlag = true;
      this.activeIndex = 4;
    },
    //鼠标移出
    ovit4() {
      let li4 = document.getElementsByClassName("fankui")[0];
      li4.style.background = "#FFF ";
      li4.style.color = "#939599";
      this.picFlag = false;
      this.activeIndex = 4;
    },
    //鼠标移入变色
    showit5() {
      let li5 = document.getElementsByClassName("check")[0];
      li5.style.background = "#1E60EB";
      li5.style.color = "#FFF";
      this.picFlag = true;
      this.activeIndex = 5;
    },
    //鼠标移出
    ovit5() {
      let li5 = document.getElementsByClassName("check")[0];
      li5.style.background = "#FFF ";
      li5.style.color = "#939599";
      this.picFlag = false;
      this.activeIndex = 5;
    },

    windowScroll() {
      // 滚动条距离页面顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop); //打印距离顶部的距离
      if (scrollTop > 500) {
        this.flag = true;
      } else if (scrollTop < 500) {
        this.flag = false;
      }
    },
    //回到顶部操作
    backTop() {
      //   console.log(document.documentElement.scrollTop);
      document.documentElement.scrollTop -= 80;
      if (document.documentElement.scrollTop > 0) {
        var time = setTimeout(() => this.backTop(80), 15);
      } else {
        clearTimeout(time);
      }
    },
    //前往意见反馈
    goFk() {
      console.log(123);
      this.$router.push({
        name: "FeedBack",
      });
    },
  },
  //清除监听页面滚动
  beforeDestroy() {
    window.removeEventListener("scroll", this.windowScroll);
  },
};
</script>
<style lang="less">
.el-tooltip__popper.is-light {
  border-color: #dedede;
  border: 1px solid #dedede;
  font-size: 16px;
  line-height: 40px;
  .banbenchose {
    width: 200px;
    .banben {
      margin: 0 auto;
      display: flex;
      align-items: center;
      width: 172px;
      height: 40px;
      border-bottom: 1px solid #ededed;
      cursor: pointer;
      img {
        margin-left: 10px;
        margin-right: 12px;
        width: 12px;
      }
      p{
        font-size: 16px;
      }
      .bbtitle {
        color: #939599;
        font-size: 12px;
      }
      .choseBb{
        color: #1E60EB;
      }
    }
    .banben:last-of-type {
      border-bottom: 0;
    }
  }
}
</style>
<style lang="less" scoped>
.container {
  position: fixed;
  top: 200px;
  right: 20px;
  width: 48px;
  // height: 400px;
  .evm {
    width: 120px;
    height: 120px;
  }
  ul {
    width: 60px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
    color: #939599;
    // background: yellow;
    box-shadow: -1px -2px 8px 0px rgb(0 0 0 / 12%);

    li {
      padding: 14px 0;
      height: 59px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      color: #939599;
      img {
        width: 24px;
      }
      span {
        display: block;
        text-align: center;
      }
    }
    // li:nth-of-type(3) {
    //   height: 40px;
    // }
    // .wechat {
    //   color: #fff;
    //   background: #1E60EB;
    // }
  }
  .goTop {
    width: 60px;
    margin-top: 20px;
    background-color: #fff;
    height: 54px;
    border-radius: 10px;
    font-size: 12px;
    color: #939599;
    text-align: center;
    cursor: pointer;
    box-shadow: -1px -2px 8px 0px rgb(0 0 0 / 12%);
    i {
      font-size: 25px;
      // margin-bottom: 5px;
    }
    span {
      display: block;
      text-align: center;
    }
  }
  .goTop:hover {
    background-color: #1E60EB;
    color: #fff;
  }
}
</style>
