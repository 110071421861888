<!--
 * @Author: your name
 * @Date: 2022-04-26 15:20:48
 * @LastEditTime: 2025-03-07 15:12:51
 * @LastEditors: 番茄炒蛋蛋 wzh15802755802@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \jzjy\src\components\myFooter.vue
-->
<template>
  <div id="box">
    <div class="aboutUs">
      <div class="top">
        <div class="left">
          <div class="callUs">
            <h2>联系我们</h2>
            <h3>CONTACT</h3>
          </div>
          <div class="usName">
            <h3>瑞鑫点教（北京）科技有限</h3><h4>公司</h4>
          </div>
          <div class="msg">
            <p>地址：北京市丰台区总部基地</p>
            <!-- <p>手机：18088886666 / 18088886666</p> -->
            <!-- <p>邮编：517000</p> -->
            <!-- <p>传真：0755-88886666</p> -->
            <p>电话：{{footerMsg.telphone}} </p>
            <!-- <p>邮箱：Windir@idianjiao.com</p> -->
          </div>
        </div>
        <div class="right">
          <div class="evmLists">
            <!-- <div class="gz">关注我们</div> -->
            <div class="erweima">
              <div class="gongzhonghao" v-if="false">
                <img :src="footerMsg.xcx_file_url" alt="" />
                <p>瑞鑫点教在线</p>
              </div>
              <div class="gongzhonghao" v-if="false">
                <!-- <img :src="footerMsg.xcx_file_url" alt="" /> -->
                <img src="" alt="">
                <p>点教助学宝</p>
              </div>
              <div class="gongzhonghao" v-for="item in evmList" :key="item.id">
                <img :src="item.file_url" alt="" />
                <p>{{item.title}}</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
      <div class="bottom" @click="getreload">
        <div>
          {{footerMsg.copyright}} 备案号：<a href="https://beian.miit.gov.cn/" style="color:#1e60eb" target="blank">京ICP备18064839号-1</a>
        </div>
        <div style="margin-top:10px">全心全意为师生服务</div>
      </div>
    </div>
  </div>
</template>

<script>
import { sysinfo, list_links,banner } from "../../api/http";
export default {
  inject: ["reload"], //刷新页面
  props: ["footerMsg"],
  name: "myFooter",
  data() {
    return {
      // telphone:'',//客服电话
      gzh_file_url: "", //客服二维码
      xcx_file_url: "", //小程序二维码
      // copyright:'',//版权
      // beian_code:'',//备案号
      linkList: [], //友情链接列表
      evmList:[],
    };
  },
  created() {
    console.log(this.footerMsg)
    banner({
      md5key: localStorage.getItem("md5key"),
      systypes_id:10012,
      device_type_id:201,
    }).then(res=>{
      this.evmList = res.retRes;
    })
    // list_links({
    //   md5key: localStorage.getItem("md5key"),
    // }).then((res) => {
    //   // console.log(res, "友情链接");
    //   // this.linkList = res.retRes;
    // });
  },
  watch: {},
  methods: {
    getreload() {
      // this.reload()
    },
    advert(href) {
      window.open(href, "_blank");
    },
    ptxy() {
      this.$router.push({
        name: "Sysinfo",
      });
    },
    gywm() {
      this.$router.push({
        name: "Gywm",
      });
    },
    lxwm() {
      this.$router.push({
        name: "Custom",
      });
    },
  },
};
</script>

<style lang="less" scoped>
#box {
  .aboutUs {
    width: 100%;
    background-color: #E1E3E6;
    color: #999;
    .top {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      margin-bottom: 20px;
      .left{
        margin-top: 20px;
        .callUs{
          margin-top: 6px;
          h2{
            margin-bottom: 8px;
            font-size: 24px;
            color: #333333;
          }
          h3{
            margin-bottom: 20px;
            font-size: 18px;
            color: #B3B3B3;
          }
        }
        .usName{
          display: flex;
          margin-bottom: 8px;
          h3{
            padding-bottom: 10px;
            font-size: 20px;
            color: #333333;
            border-bottom: 1px solid #666666;
          }
          h4{
            font-size: 20px;
            color: #333333;
          }
        }
        .msg{
          display: flex;
          // flex-direction: column;
          // flex-wrap: wrap;
          align-items: center;
          width: 500px;
          // height: 94px;
          p{
            line-height: 30px;
            width: 250px;
            color: #666666;
            font-size: 14px;
          }
        }
        
      }
      .right{
        // margin-top: 40px;
        // margin-bottom: 40px;
        .evmLists{
          margin-top: 30px;
          // margin-top: 16px;
          .gz{
            margin-bottom: 10px;
            font-size: 16px;
            color: #333333;
            line-height: 34px;
          }
          .erweima {
            display: flex;
            margin-left: 20px;
            img {
              margin-bottom: 6px;
              width: 120px;
              // height: 88px;
            }
            p {
              text-align: center;
              color: #666666;
            }
            .gongzhonghao {
              margin-left: 20px;
            }
          }
        }
      }
    }
    
    .bottom {
      overflow: hidden;
      margin: 0px auto;
      padding: 20px 0;
      text-align: center;
      border-top: 1px solid #d4d4d4;
      overflow: hidden;
      background: #494B4D;
    }
  }
}
</style>
