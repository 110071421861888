/*
 * @Author: wangwang 2723008256@qq.com
 * @Date: 2022-06-27 09:45:59
 * @LastEditors: 番茄炒蛋蛋 wzh15802755802@163.com
 * @LastEditTime: 2025-03-25 16:08:47
 * @FilePath: \jzjypc4.0\src\api\http.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {get,post} from '../utils/request'

//登录
export const login = data => post('api.php/Index/login?from=ajax',data)

//注册
export const reg = data => post('api.php/Index/reg?from=ajax',data)

//检测是否注册
export const checkaccount = data => post('api.php/Index/checkaccount?from=ajax',data)

//获取登录二维码
export const wxdlewm = data => post('api.php/xcx/wxdlewm?from=ajax',data)

//获取扫码登录状态
export const upwxdl = data => post('api.php/xcx/upwxdl?from=ajax',data)

//退出登录
export const logout = data => post('api.php/Index/logout?from=ajax',data)

//是否登录
export const islogin = data => post('api.php/Index/islogin?from=ajax',data)

//系统介绍信息
export const sysinfo_info = data => post('api.php/Index/sysinfo_info?from=ajax',data)

//七牛云配置
export const qiniuconfig = data => post('api.php/Index/qiniuconfig?from=ajax',data)

// 发送短信验证码
export const sendverf = data => post('api.php/Index/sendverf?from=ajax',data)

// 验证码验证手机号
export const checkphone = data => post('api.php/Index/checkphone?from=ajax',data)

// 验证码验证手机号用于证书
export const studycertcheckphone = data => post('api.php/api2/studycertcheckphone?from=ajax',data)

// 验证码验证手机号
export const findpassbyphone = data => post('api.php/Index/findpassbyphone?from=ajax',data)

//修改密码
export const pswupdate = data => post('api.php/Index/pswupdate?from=ajax',data)

//热搜词
export const keywordslists = data => post('api.php/Index/keywordslists?from=ajax',data)

//系统分类列表
export const list_systypes = data => post('api.php/Index/list_systypes?from=ajax',data)

// 登录用户详情
export const userinfo = data => post('api.php/Index/userinfo?from=ajax',data)

//修改用户信息
export const setuserinfo = data => post('api.php/Index/setuserinfo?from=ajax',data)

//年级列表
export const nianjilists = data => post('api.php/Index/nianjilists?from=ajax',data)

//学校列表
export const school = data => post('api.php/Index/school?from=ajax',data)

//创建学校
export const checkschool = data => post('api.php/Index/checkschool?from=ajax',data)

//科目列表
export const kemulists = data => post('api.php/Index/kemulists?from=ajax',data)

//获取省市区数据
export const pca = data => post('api.php/Index/pca?from=ajax',data)

//当前科目和版本
export const nowkemu = data => post('api.php/Index/nowkemu?from=ajax',data)

//设置当前科目和版本
export const setnowkemu = data => post('api.php/Index/setnowkemu?from=ajax',data)

//提交意见反馈
export const create_yjfk = data => post('api.php/Index/create_yjfk?from=ajax',data)

//版本列表
export const versionslists = data => post('api.php/Index/versionslists?from=ajax',data)

//版本列表含书本
export const versionslistsinfo = data => post('api.php/Index/versionslistsinfo?from=ajax',data)

//底部系统信息
export const sysinfo = data => post('api.php/Index/sysinfo?from=ajax',data)

//友情链接
export const list_links = data => post('api.php/Index/list_links?from=ajax',data)

//banner图
export const banner = data => post('api.php/Index/list_banner?from=ajax',data)

//banner图
export const list_bannertext = data => post('api.php/Index/list_bannertext?from=ajax',data)

//主页数据统计
export const sjtj = data => post('api.php/Index/sjtj?from=ajax',data)

//主页推荐活动
export const activityindex = data => post('api.php/Index/activityindex?from=ajax',data)

//主页推荐活动
export const getbookbyprogress = data => post('api.php/api2/getbookbyprogress?from=ajax',data)

//获取关键时间节点  
export const mytimeline = data => post('api.php/Index/mytimeline?from=ajax',data)

//设置关键时间节点  
export const setmytimeline = data => post('api.php/Index/setmytimeline?from=ajax',data)

//我的教学计划
export const myplan = data => post('api.php/Index/myplan?from=ajax',data)

//设置我的教学计划
export const setmyplan = data => post('api.php/Index/setmyplan?from=ajax',data)

//书本列表
export const bookslists = data => post('api.php/Index/bookslists?from=ajax',data)

//书本目录列表
export const chapterslists = data => post('api.php/Index/chapterslists?from=ajax',data)

//书本目录详情
export const chaptersinfo = data => post('api.php/Index/chaptersinfo?from=ajax',data)

//课件分类
export const kejianfl = data => post('api.php/Index/kejianfl?from=ajax',data)

//课件推荐
export const tjlists = data => post('api.php/Index/tjlists?from=ajax',data)

//课件列表标签
export const list_kejiantag = data => post('api.php/Index/list_kejiantag?from=ajax',data)

//课件列表
export const list_kejian = data => post('api.php/Index/list_kejian?from=ajax',data)

//教学课教研课列表
export const list_jyjxk = data => post('api.php/Index/list_jyjxk?from=ajax',data)

//课件详情
export const info_kejian = data => post('api.php/Index/info_kejian?from=ajax',data)

//获取课件下载链接
export const url_kejian = data => post('api.php/Index/url_kejian?from=ajax',data)

//获取课件批量下载链接
export const plxz_url_arr = data => post('api.php/Index/plxz_url_arr?from=ajax',data)

//获取课件详情页的推荐课件
export const tjkejian = data => post('api.php/Index/tjkejian?from=ajax',data)

//课件包列表
export const tjlists_dy = data => post('api.php/Index/tjlists_dy?from=ajax',data)

//课件包列表
export const list_kejianbao = data => post('api.php/Index/list_kejianbao?from=ajax',data)

//课件包数据信息汇总
export const zyzxinfo = data => post('api.php/Index/zyzxinfo?from=ajax',data)

//高三课件包数据信息汇总
export const zyzxgs = data => post('api.php/Index/zyzxgs?from=ajax',data)

//资源中心是否有用的反馈
export const zyzxfk = data => post('api.php/Index/zyzxfk?from=ajax',data)

//课件包详情
export const info_kejianbao = data => post('api.php/Index/info_kejianbao?from=ajax',data)

//获取课件下载链接【资源包】
export const url_kejianbao = data => post('api.php/Index/url_kejianbao?from=ajax',data)

//单元的教研课视频
export const jyjxkinfolists = data => post('api.php/Index/jyjxkinfolists?from=ajax',data)

//教研课详情
export const info_jyjxk = data => post('api.php/Index/info_jyjxk?from=ajax',data)

//视频库详情
export const info_videos = data => post('api.php/Index/info_videos?from=ajax',data)

//资源详情页推荐图书
export const getbookbyresource = data => post('api.php/api2/getbookbyresource?from=ajax',data)

//获取我的下载记录
export const xzjl = data => post('api.php/Index/xzjl?from=ajax',data)

//删除我的下载记录
export const del_xzjl = data => post('api.php/Index/del_xzjl?from=ajax',data)

//获取我的活动
export const activitylists_my = data => post('api.php/Index/activitylists_my?from=ajax',data)

//获取课件订阅列表
export const list_kejiandingyue	= data => post('api.php/Index/list_kejiandingyue?from=ajax',data)

//获取精选资源课件订阅列表
export const list_kejiandingyue_tj	= data => post('api.php/Index/list_kejiandingyue_tj?from=ajax',data)

//课件订阅详情
export const info_kejiandingyue	= data => post('api.php/Index/info_kejiandingyue?from=ajax',data)

//课件订阅时间列表
export const list_kejiandingyuetime	= data => post('api.php/Index/list_kejiandingyuetime?from=ajax',data)

//课件订阅时间详情
export const info_kejiandingyuetime	= data => post('api.php/Index/info_kejiandingyuetime?from=ajax',data)

//订阅推送
export const dyts = data => post('api.php/Index/dyts?from=ajax',data)

//课件订阅数量
export const nums_kejiandingyue = data => post('api.php/Index/nums_kejiandingyue?from=ajax',data)

//帮助文档分类
export const list_bzwdtype = data => post('api.php/Index/list_bzwdtype?from=ajax',data)

//帮助文档列表
export const list_bzwd = data => post('api.php/Index/list_bzwd?from=ajax',data)

//帮助文档详情
export const info_bzwd = data => post('api.php/Index/info_bzwd?from=ajax',data)


//活动页活动页活动页活动页活动页活动页活动页活动页活动页活动页活动页

//全局判断是否系列活动弹窗
export const tk_activity = data => post('api.php/Index/tk_activity?from=ajax',data)

//活动页活动推荐
export const activitytop = data => post('api.php/Index/activitytop?from=ajax',data)

//活动页教室列表
export const list_teachers = data => post('api.php/Index/list_teachers?from=ajax',data)

//活动页活动统计
export const activitytj = data => post('api.php/Index/activitytj?from=ajax',data)

//活动页活动统计-新4.2版本
export const activitytj_new = data => post('api.php/Index/activitytj_new?from=ajax',data)

//活动页活动列表
export const activitylists = data => post('api.php/Index/activitylists?from=ajax',data)

//活动页活动列表4.2
export const activitylists02 = data => post('api.php/Index/activitylists02?from=ajax',data)

//活动详情
export const info_activity = data => post('api.php/Index/info_activity?from=ajax',data)

//活动报告详情
export const info_activitybg = data => post('api.php/Index/info_activitybg?from=ajax',data)

//活动播放时间上报	
export const playtimes_activity = data => post('api.php/Index/playtimes_activity?from=ajax',data)

//活动章节详情
export const info_activityjie = data => post('api.php/Index/info_activityjie?from=ajax',data)

//活动作业详情
export const info_activityzy = data => post('api.php/Index/info_activityzy?from=ajax',data)

//活动详情页推荐图书
export const recommendbook = data => post('api.php/api2/recommendbook?from=ajax',data)

//活动成果
export const activitycg = data => post('api.php/Index/activitycg?from=ajax',data)

//活动评价 4.2
export const list_activitypj = data => post('api.php/Index/list_activitypj?from=ajax',data)

//活动图片列表
export const list_activityimg = data => post('api.php/Index/list_activityimg?from=ajax',data)

//活动图片列表4.2
export const list_activityimg02 = data => post('api.php/Index/list_activityimg02?from=ajax',data)

//活动心得列表
export const list_activityhdxd = data => post('api.php/Index/list_activityhdxd?from=ajax',data)

//活动心得详情
export const info_activityhdxd = data => post('api.php/Index/info_activityhdxd?from=ajax',data)

//活动课件列表
export const list_activitykejian = data => post('api.php/Index/list_activitykejian?from=ajax',data)

//活动课件详情
export const info_activitykejian = data => post('api.php/Index/info_activitykejian?from=ajax',data)

//活动报名
export const bm_activity = data => post('api.php/Index/bm_activity?from=ajax',data)

//活动预约  获取当前地区客服二维码
export const loc_kefuewm = data => post('api.php/Index/loc_kefuewm?from=ajax',data)

//活动提交作业(暂存)
export const tjzy_activity = data => post('api.php/Index/tjzy_activity?from=ajax',data)

//活动提交作业（提交）
export const settj_activityzy = data => post('api.php/Index/settj_activityzy?from=ajax',data)

//直播间聊天室信息
export const chatinfo = data => post('api.php/Index/chatinfo?from=ajax',data)

//聊天室用户列表
export const list_chatuser = data => post('api.php/Index/list_chatuser?from=ajax',data)

//聊天室评论列表
export const chatmsglists = data => post('api.php/Index/chatmsglists?from=ajax',data)

//上报停留时长
export const page_times = data => post('api.php/Index/page_times?from=ajax',data)

//上报停留时长
export const data_activity = data => post('api.php/Index/data_activity?from=ajax',data)


//产品--产品--产品--产品--产品
//产品中心首页
export const productinfo = data => post('api.php/api2/producttslist?from=ajax',data)

//图书详情
export const bookdetail = data => post('api.php/api2/bookdetail?from=ajax',data)

//申领样书
export const askbook = data => post('api.php/api2/askbook?from=ajax',data)

//图书配套资源
export const getproductbookkejian = data => post('api.php/api2/getproductbookkejian?from=ajax',data)

// //课程详情
// export const coursedetail = data => post('api.php/api2/coursedetail?from=ajax',data)

//精听训练营
export const jtdetail = data => post('api.php/api2/jtdetail?from=ajax',data)

// 新增，高三英语一轮复习词汇和语法
export const chapterskejian = data => post('api.php/Index/chapterskejian?from=ajax',data)

// 新增，高三英语二轮复习词汇和语法
export const chapterskejian_new = data => post('api.php/Index/chapterskejian_new?from=ajax',data)


//收藏
// 个人中心我的收藏--课件收藏列表
export const list_accountzysc = data => post('api.php/Index/list_accountzysc?from=ajax',data)

// 个人中心我的收藏--资源包专题收藏列表
export const list_accountzysc_kejianbao = data => post('api.php/Index/list_accountzysc_kejianbao?from=ajax',data)

// 收藏【添加/删除】
export const create_accountzysc = data => post('api.php/Index/create_accountzysc?from=ajax',data)

// 生成小程序码
export const getxcxewm = data => post('api.php/xcx/getxcxewm?from=ajax',data)

// 生成小程序码
export const getdlj = data => post('api.php/xcx/getdlj?from=ajax',data)

// 临时字符串【创建】
export const create_temptext = data => post('api.php/index/create_temptext?from=ajax',data)

// 临时字符串【详情】
export const info_temptext = data => post('api.php/index/info_temptext?from=ajax',data)

// 使用解锁码	
export const sy_goodsjsm = data => post('api.php/index/sy_goodsjsm?from=ajax',data)


// 订单【详情】
export const info_orders = data => post('api.php/index/info_orders?from=ajax',data)


// 活动分类及标签
export const list_activityfl = data => post('api.php/index/list_activityfl?from=ajax',data)

// 活动首页课程列表	
export const activityindex_new = data => post('api.php/index/activityindex_new?from=ajax',data)

// 活动省份【列表】
export const list_activitypca = data => post('api.php/index/list_activitypca?from=ajax',data)

// 活动列表【改版0726】
export const activityindex_lists = data => post('api.php/index/activityindex_lists?from=ajax',data)

// 活动列表【改版0726】
export const sjtj_hd = data => post('api.php/index/sjtj_hd?from=ajax',data)

// 教材教学课【详情】
export const info_jcjxk = data => post('api.php/index/info_jcjxk?from=ajax',data)

// 商品【列表】
export const list_goods = data => post('api.php/index/list_goods?from=ajax',data)

// 商品【详情】
export const info_goods = data => post('api.php/index/info_goods?from=ajax',data)

// 订单【删除】
export const delete_orders = data => post('api.php/index/delete_orders?from=ajax',data)

// 订单详情-申请开票
export const askordersfp = data => post('api.php/api2/askordersfp?from=ajax',data)

// 订单详情-领取赠品
export const askorderszp = data => post('api.php/api2/askorderszp?from=ajax',data)

// 个人中心-我的课程页日历统计
export const mycourse = data => post('api.php/api2/mycourse?from=ajax',data)

// 个人中心-我的课程页课程列表
export const my_jcjxk = data => post('api.php/index/my_jcjxk?from=ajax',data)

// 判断企业微信是否添加
export const qywx_status = data => post('api.php/index/qywx_status?from=ajax',data)

// 订单支付
export const orderspay = data => post('api.php/index/orderspay?from=ajax',data)

// 课件下载次数限制
export const download_nums = data => post('api.php/index/download_nums?from=ajax',data)

// 用户是否订阅、是否支付
export const ffzyb_status = data => post('api.php/index/ffzyb_status?from=ajax',data)

// 判断当前用户是否需要服务包更新弹窗	
export const getgoodsweekpopup = data => post('api.php/api2/getgoodsweekpopup?from=ajax',data)

// 关闭服务包更新弹窗		
export const closepopup = data => post('api.php/api2/closepopup?from=ajax',data)

// 根据课件id/课件包id获取服务包推荐		
export const getgoodsbykejian = data => post('api.php/api2/getgoodsbykejian?from=ajax',data)

// 根据课件id/课件包id获取goods支付状态	
export const getgmstatusbykejian = data => post('api.php/api2/getgmstatusbykejian?from=ajax',data)

// 商品规格/套餐【详情】 //去学习首页
export const info_goodsspec = data => post('api.php/api2/info_goodsspec?from=ajax',data)

// 创建订单
export const create_orders = data => post('api.php/api2/create_orders?from=ajax',data)

// 根据活动id获取订阅广告信息
export const getggbyactivity = data => post('api.php/api2/getggbyactivitys?from=ajax',data)

// 订单【列表】
export const list_orders = data => post('api.php/api2/list_orders?from=ajax',data)

// 根据地区获取咨询/预约客服信息
export const getkfinfobyip = data => post('api.php/api2/getkfinfobyip?from=ajax',data)

// 商品分类列表
export const list_goodstype = data => post('api.php/api2/list_goodstype?from=ajax',data)

// 学时证书记录列表
export const studycertloglist = data => post('api.php/api2/studycertloglist?from=ajax',data)

// 查询证书详情
export const studycertinfo = data => post('api.php/api2/studycertinfo?from=ajax',data)

// 生成学时证书	
export const createstudycert = data => post('api.php/api2/createstudycert?from=ajax',data)

// 保存证书图片	
export const studycertimgtolocal = data => post('api.php/api2/studycertimgtolocal?from=ajax',data)

// 删除证书记录	
export const delstudycertlog = data => post('api.php/api2/delstudycertlog?from=ajax',data)

// 学时证书用户信息
export const studycertaccountinfo = data => post('api.php/api2/studycertaccountinfo?from=ajax',data)

// 更新学时证书用户信息,设置学时证书用户信息	
export const setstudycertaccountinfo = data => post('api.php/api2/setstudycertaccountinfo?from=ajax',data)

// 获取资源包评论列表	
export const getgoodscommentlist = data => post('api.php/api2/getgoodscommentlist?from=ajax',data)

// 发表资源包评论		
export const addgoodscomment = data => post('api.php/api2/addgoodscomment?from=ajax',data)

// 修改评论,编辑评论		
export const updategoodscomment = data => post('api.php/api2/updategoodscomment?from=ajax',data)

// 根据课件包id获取教辅图书课件列表
export const getprokjlistbykejianbaoid = data => post('api.php/api2/getprokjlistbykejianbaoid?from=ajax',data)

// 根据课件包id获取教辅图书课件列表
export const groupbuygoodsinfo = data => post('api.php/api2/groupbuygoodsinfo?from=ajax',data)

// 获取当前用户集采服务包身份
export const getgroupbuyuserinfo = data => post('api.php/api2/getgroupbuyuserinfo?from=ajax',data)

//教研包下载跳转相关

// 根据手机号获取用户是否注册变现平台
export const accountexistbx = data => post('api.php/api2/accountexistbx?from=ajax',data)

// 获取变现身份列表,职务列表
export const list_bxshenfen = data => post('api.php/api2/list_bxshenfen?from=ajax',data)

// 注册变现平台	
export const regbx = data => post('api.php/api2/regbx?from=ajax',data)

// 发送短信验证码(变现,助学宝)	
export const sendverfbx = data => post('api.php/api2/sendverfbx?from=ajax',data)

// 是否登录(变现,助学宝)
export const isloginbx = data => post('api.php/api2/isloginbx?from=ajax',data)

// 登录(变现,助学宝)
export const loginbx = data => post('api.php/api2/loginbx?from=ajax',data)

// 未登录下载资源链接	
export const url_kejian_jyb = data => post('api.php/api2/url_kejian?from=ajax',data)

// 获取变现系统信息
export const sysinfobx = data => post('api.php/api2/sysinfobx?from=ajax',data)

// 根据地区获取客服(变现)
export const getjyhdkefuworkwxbyipbx = data => post('api.php/api2/getjyhdkefuworkwxbyipbx?from=ajax',data)

// 用户是否已添加企业微信(变现)
export const qywx_statusbx = data => post('api.php/api2/qywx_statusbx?from=ajax',data)

// 活动主页修改
export const list_banner_bx = data => post('api.php/index/list_banner_bx?from=ajax',data)

// 活动主页修改
export const list_zbhd = data => post('api.php/index/list_zbhd?from=ajax',data)

// 活动主页修改
export const list_zbhd_index = data => post('api.php/index/list_zbhd_index?from=ajax',data)

// 名师工作室【列表】
export const list_starteacherstudio = data => post('api.php/api2/list_starteacherstudio?from=ajax',data)

// 名师工作室【详情】
export const info_starteacherstudio = data => post('api.php/api2/info_starteacherstudio?from=ajax',data)

// 名师工作室资源列表
export const list_starteacherstudio_res = data => post('api.php/api2/list_starteacherstudio_res?from=ajax',data)

// 申请入驻名师工作室
export const add_starteacherstudio = data => post('api.php/api2/add_starteacherstudio?from=ajax',data)

// 创建订单【购买课件】
export const create_orders_kejian = data => post('api.php/index/create_orders_kejian?from=ajax',data)

// 课件是否打过分
export const dfinfo_kejian = data => post('api.php/index/dfinfo_kejian?from=ajax',data)

// 给课件打分
export const df_kejian = data => post('api.php/index/df_kejian?from=ajax',data)

// 教研平台新增判断用户是否加过直播活动7人二维码 
export const getliveactivitykefuewmstatus = data => post('api.php/api2/getliveactivitykefuewmstatus?from=ajax',data)

// 教研平台 获取系统客服二维码 
export const getsyskefuewm = data => post('api.php/api2/getsyskefuewm?from=ajax',data)

// 教研平台 AI应用列表
export const list_aitype = data => post('api.php/index/list_aitype?from=ajax',data)

// 教研平台 AI应用列表 -- 详情
export const info_aitype = data => post('api.php/index/info_aitype?from=ajax',data)

// 教研平台 AI对话【创建】
export const create_aichat = data => post('api.php/index/create_aichat?from=ajax',data)

// 教研平台 AI对话【记录】
export const list_aichat = data => post('api.php/index/list_aichat?from=ajax',data)

// 教研平台 AI对话【详情】
export const info_aichat = data => post('api.php/index/info_aichat?from=ajax',data)

// 教研平台 AI对话【更新】
export const update_aichat = data => post('api.php/index/update_aichat?from=ajax',data)

// 教研平台 AI对话【删除】
export const delete_aichat = data => post('api.php/index/delete_aichat?from=ajax',data)

// 教研平台 AI应用token
export const ai_token = data => post('api.php/index/ai_token?from=ajax',data)

// 教研平台 AI对话【聊天记录】
export const aichatlog = data => post('api.php/index/aichatlog?from=ajax',data)
