var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('header',[_c('div',{staticClass:"header"},[_c('myHeader',{attrs:{"path":_vm.path,"headerMsg":_vm.headerMsg,"ziyuanPath":_vm.ziyuanPath},on:{"searchRes":_vm.searchRes}})],1)]),_c('main',[_c('router-view',{attrs:{"xitongMsg":_vm.footerMsg,"kefuMsg":_vm.kefuMsg},on:{"pathpath":_vm.pathpath}}),(_vm.path != '/active/fullScreen')?_c('div',{staticClass:"floating"},[_c('floating',{attrs:{"floaterMsg":_vm.floaterMsg}})],1):_vm._e(),(true)?_c('div',{staticClass:"activekctc"},[_c('el-dialog',{attrs:{"visible":_vm.actcyytxShow,"width":"1000px"},on:{"update:visible":function($event){_vm.actcyytxShow=$event}}},[_c('div',{staticClass:"yytxBox"},[(_vm.bm_status == 0)?_c('h3',[_vm._v("活动预约课程表")]):_vm._e(),(_vm.bm_status == 1)?_c('h3',[_vm._v("学习提醒")]):_vm._e(),_c('div',{staticClass:"tcContent"},[_c('div',{staticClass:"tcleft"},[_c('img',{attrs:{"src":_vm.hb_file_url,"alt":""}})]),_c('div',{staticClass:"tcright"},_vm._l((_vm.zhang_lists),function(item){return _c('div',{key:item.id,staticClass:"danyuan"},[_c('div',{staticClass:"mltitle",on:{"click":function($event){return _vm.choseDy(item.id, item.zytj_status)}}},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"jiantou"},[_c('span',[(_vm.mulutapValue == item.id)?_c('img',{attrs:{"src":require("../assets/img/acrestop.png")}}):_vm._e()]),_c('span',[(_vm.mulutapValue != item.id)?_c('img',{attrs:{"src":require("../assets/img/acresdown.png")}}):_vm._e()])])]),_vm._l((item.jie_lists),function(list,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mulutapValue == item.id),expression:"mulutapValue == item.id"}],key:list.id,staticClass:"muluLists",class:{
                    nowmulu: list.xxjd_info.status == 1,
                    beforemulu:
                      list.start_time_true * 1000 > new Date().getTime(),
                  }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(list.xxjd_info.status == 2),expression:"list.xxjd_info.status == 2"}],staticClass:"leftTips"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                      list.xxjd_info.status != 2 &&
                      list.start_time_true * 1000 < new Date().getTime()
                    ),expression:"\n                      list.xxjd_info.status != 2 &&\n                      list.start_time_true * 1000 < new Date().getTime()\n                    "}],staticClass:"leftTips",staticStyle:{"background-color":"#1e60eb"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                      list.start_time_true * 1000 > new Date().getTime()
                    ),expression:"\n                      list.start_time_true * 1000 > new Date().getTime()\n                    "}],staticClass:"leftTips",staticStyle:{"background-color":"#ccc"}}),_c('div',{staticClass:"muluIndex"},[_c('h3',[_vm._v("0"+_vm._s(index + 1))])]),_c('div',{staticClass:"mulumsg"},[_c('div',{staticClass:"listTitle"},[_vm._v(" "+_vm._s(list.title)+" ")]),_c('div',{staticClass:"mulubottom"},[_c('div',{staticClass:"timelong"},[_vm._v(" 课程时长："+_vm._s(list.shichang / 60)+"min ")]),_c('div',{staticClass:"studyjd"},[_vm._v(" 学习进度："+_vm._s(list.xxjd_info.jd)+"% ")])])]),_c('div',{staticClass:"muluright"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        list.xxjd_info.status == 2 &&
                        list.start_time_true * 1000 < new Date().getTime()
                      ),expression:"\n                        list.xxjd_info.status == 2 &&\n                        list.start_time_true * 1000 < new Date().getTime()\n                      "}],staticClass:"yixuewan zhuangtai"},[_c('img',{attrs:{"src":require("../assets/img/acresyixuewan.png"),"alt":""}}),_c('span',[_vm._v("已学完")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        list.xxjd_info.status == 1 &&
                        list.start_time_true * 1000 < new Date().getTime()
                      ),expression:"\n                        list.xxjd_info.status == 1 &&\n                        list.start_time_true * 1000 < new Date().getTime()\n                      "}],staticClass:"yixuewan zhuangtai"},[_c('img',{attrs:{"src":require("../assets/img/acresxuexizhong.png"),"alt":""}}),_c('span',[_vm._v("学习中")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        list.xxjd_info.status == 0 &&
                        list.start_time_true * 1000 < new Date().getTime()
                      ),expression:"\n                        list.xxjd_info.status == 0 &&\n                        list.start_time_true * 1000 < new Date().getTime()\n                      "}],staticClass:"yixuewan zhuangtai"},[_c('img',{attrs:{"src":require("../assets/img/acresweixuexi.png"),"alt":""}}),_c('span',[_vm._v("未学习")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                        list.start_time_true * 1000 > new Date().getTime()
                      ),expression:"\n                        list.start_time_true * 1000 > new Date().getTime()\n                      "}],staticClass:"yixuewan zhuangtai"},[_c('img',{attrs:{"src":require("../assets/img/acresweikaiqi.png"),"alt":""}}),_c('span',[_vm._v("未开启")])])])])})],2)}),0)]),(_vm.bm_status == 0)?_c('div',{staticClass:"dosome",on:{"click":_vm.goacdetails}},[_vm._v("查看详情")]):_vm._e(),(_vm.bm_status == 1)?_c('div',{staticClass:"dosome",on:{"click":_vm.goacdetails}},[_vm._v("立即学习")]):_vm._e()])])],1):_vm._e()],1),_c('footer',[(
        _vm.path != '/dingzhi'||
        _vm.path != '/resource/jiaoyanClass' ||
        _vm.path != '/product/fullScreenBooks'
      )?_c('div',{staticClass:"footer"},[_c('myFooter',{attrs:{"footerMsg":_vm.footerMsg}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }